import { GlobalStyle, theme, useColorModeValue, useStyleConfig } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";


import Home from "./pages/home";
import Pixel from "./pages/pixel";



function App() {

  return (
    <>
      <Routes>
        <Route path="*" element={<Home/>}/>
        <Route path="/pixel" element={<Pixel/>}/>
      </Routes>
    </>
  );
}

export default App;
