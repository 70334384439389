import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/painter.json"
import { PencilHandler } from '@svg-drawing/core'
import {
    Svg,
    useDraw,
    useDrawFactory,
    useDrawEventHandler,
    usePencilHandler,
    useSvg,
} from '@svg-drawing/react'

import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSvgDrawing } from "react-hooks-svgdrawing";
import { SwatchesPicker } from "react-color";
const optimize = require('svgo/dist/svgo.browser').optimize


const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = useMemo(() => new ethers.Contract('0x0664cD90Ad986dE989d8A5DfADeb94E5596F7541', abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [color, setColor] = useState("000000")
    const [allowed, setAllowed] = useState(true)
    const handleError = (err) => {
        toast({
            title: "Error",
            description: (err.reason || err.message),
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }, [provider, signer])



    const [renderRef, draw] = useSvgDrawing({
        penWidth: 10, // pen width
        penColor: 'black', // pen color
        close: false, // Use close command for path. Default is false.
        curve: true, // Use curve command for path. Default is true.
        delay: 50, // Set how many ms to draw points every.
        fill: "none", // Set fill attribute for path. default is `none`
    });
    const handleDownload = useCallback(() => {
        draw.download(); // default svg download
        draw.download({ extension: "svg", filename: "a.svg" });
        draw.download({ extension: "png", filename: "b.png" });
        draw.download({ extension: "jpg", filename: "c.jpg" });
    }, [draw]);
    const handleChangeParameter = useCallback(() => {
        // Change pen config draw.changePenColor("#00b");
        // Change pen width draw.changePenWidth(10);
        // Change fill attribure of svg path element. draw.changFill("#00b");
        // Change throttle delay of drawing draw.changeDelay(10);
        // Set whether to use curved comma for svg path element. draw.changCurve(false);
        // Set whether to use curved comma for svg path element. draw.changeClose(true);
    }, []);
    useColorMode().setColorMode("dark")

    useEffect(() => {
        connect()
        constants.changeNetwork(43114)
    }, [connect])

    const mint = async () => {
        const svg = draw.getSvgXML().replace(/svg width="\d*\.{0,1}\d*" height="\d*\.{0,1}\d*"/gm, 'svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"')
        console.log(svg)
        if(!svg.includes('viewBox')){
            toast({
                title: "Error",
                description: "SVG is invalid",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            return
        }
        const optimized = optimize(svg, {multipass: true})
        console.log(optimized.data)
        const bytes = ethers.utils.toUtf8Bytes(optimized.data)
        const tx = await contract.mint(bytes).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }


    return (
        <Container maxW="container.xl" centerContent p='50'>
            <Heading>Draw your NFT On-Chain</Heading>
            <Button onClick={connect}>Connect</Button>
            <Text>The images and metadata will be stored COMPLETELY on-chain</Text>
            <Text>(the more you draw the more gas it costs. if you draw too much, you get "execution reverted" errors)</Text>
            <Link isExternal href="https://joepegs.com/collections/avalanche/0x0664cd90ad986de989d8a5dfadeb94e5596f7541" color={'teal'} sx={{":hover":{textDecor:"underline"}}}>view collection</Link>
            <Link href="/pixel" color={'teal'} sx={{":hover":{textDecor:"underline"}}}>
                <Button>draw pixel art</Button>
            </Link>
            <VStack>

                <div
                    style={{ width: 500, height: 500, border: "1px solid black" }}
                    ref={renderRef} />

                <Text>change pen width</Text>
                <Slider defaultValue={10} min={1} max={100} onChange={(value) => draw.changePenWidth(value)}>
                    <SliderTrack>
                        <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                </Slider>

                <ButtonGroup>
                    <Button onClick={draw.clear}>clear</Button>
                    <Button onClick={draw.undo}>undo</Button>
                    <Button onClick={mint}>mint</Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button onClick={() => draw.changeFill(color)}>enable fill</Button>
                    <Button onClick={() => draw.changeFill('none')}>disable fill</Button>
                </ButtonGroup>
                <SwatchesPicker onChange={e => {
                    draw.changePenColor(e.hex)
                    setColor(e.hex)
                }} />

            </VStack>


        </Container>

    );
}