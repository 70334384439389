import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Img, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/painter.json"
import { ChevronDownIcon } from "@chakra-ui/icons";
import { SwatchesPicker } from "react-color";
import { PixelEditor, Pencil } from '@curtishughes/pixel-editor';
import { SVG } from "@svgdotjs/svg.js";
const optimize = require('svgo/dist/svgo.browser').optimize


const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Pixel() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = useMemo(() => new ethers.Contract('0x0664cD90Ad986dE989d8A5DfADeb94E5596F7541', abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [color, setColor] = useState("000000")
    const [allowed, setAllowed] = useState(true)
    const handleError = (err) => {
        toast({
            title: "Error",
            description: (err.reason || err.message),
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }, [provider, signer])

    const editorRef = useRef(null)
    const [editor, setEditor] = useState(null)//new PixelEditor(editorRef.current, 16, 16, new Pencil(color)))

    useEffect(() => {
        if (editorRef.current && !editor) {
            setEditor(new PixelEditor(editorRef.current, 16, 16, new Pencil(color)))
        }

    }, [editorRef, color, editor])


    const mint = async () => {
        const svg = SVG().size(500, 500)
        editor.pixels.forEach((pixel, y) => {
            svg.rect(500 / 16, 500 / 16).fill(pixel.color).move(500 / 16 * pixel.x, 500 / 16 * pixel.y)
        })
        const svgString = svg.svg().replace(/<svg[^>]*>/, '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">')
        console.log(svgString)
        const optimized = optimize(svgString, { multipass: true })
        console.log(optimized.data)
        const bytes = ethers.utils.toUtf8Bytes(optimized.data)
        const tx = await contract.mint(bytes).catch(handleError)
        await tx.wait()
        toast({
            title: "Success",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }
    return (
        <>
            <Container maxW="container.xl" centerContent>
                <VStack spacing={4} w="100%">
                    <Heading>Draw your NFT On-Chain</Heading>
                    <Button onClick={connect}>Connect</Button>
                    <Text>The images and metadata will be stored COMPLETELY on-chain</Text>
                    <Text>(the more you draw the more gas it costs. if you draw too much, you get "execution reverted" errors)</Text>
                    <Link isExternal href="https://joepegs.com/collections/avalanche/0x0664cd90ad986de989d8a5dfadeb94e5596f7541" color={'teal'} sx={{ ":hover": { textDecor: "underline" } }}>view collection</Link>
                    <Link href="/" color={'teal'} sx={{ ":hover": { textDecor: "underline" } }}>
                        <Button>paint</Button>
                    </Link>
                    <Box w="500px" h="500px" bg="gray.100" borderRadius="lg" p={4}>
                        <canvas ref={editorRef} width="500" height="500" />
                    </Box>
                    <ButtonGroup>
                        <Button onClick={() => {
                            editor.clear()
                        }}>Clear</Button>
                        <Button onClick={() => {
                            editor.undo()
                        }}>Undo</Button>
                        <Button onClick={() => {
                            editor.redo()
                        }}>Redo</Button>
                    </ButtonGroup>
                    <Button onClick={mint}>mint</Button>

                    <SwatchesPicker color={color} onChange={(color) => {
                        editor.tool = new Pencil(color.hex)
                    }} />
                </VStack>
            </Container>
        </>
    )
}